import React from 'react'
import BookButton from './BookButton'
import { HashLink as Link } from 'react-router-hash-link';

function Coaching({coaching}) {
  return (<div style={{backgroundImage: `url(${coaching.img_url})`, backgroundSize: 'cover', backgroundPosition: 'center'}}>
    <div className='text-white lg:mx-auto py-10 w-full xl:w-2/5 lg:border-x-4 lg:border-r-white backdrop-brightness-50' >
      <div className='mx-10'>
        <p className='text-center font-bold text-lg sm:text-xl mb-5'>REGAIN YOUR ENERGY AND FEEL YEARS YOUNGER IN JUST 90 DAYS</p>
        <h2 className='text-center font-bold text-xl mb-5'>{coaching.name}</h2>
        <p className=''>Benefit from a 1-on-1 coaching with me where I'll be directly in touch with you during your fitness journey. The program will be adapted to you individually in order to get the best results.</p>
        {/*<p className='text-center mb-5'>{coaching.price}€ / month - Cancel anytime</p>*/}
        <ul className='leading-10 mb-5 list-disc'>
          {coaching.descriptions.map((item, index) => {
            return (
              <li key={index} className="ml-10">{item}</li>
            )
          })}
        </ul>
        <p className='mb-10'>Apply for a FREE call first to see how we can work together.</p>
        <Link to="/#contact">
          <BookButton text="Apply now"/>
        </Link>
      </div>
    </div>
  </div>
  )
}

export default Coaching
